import whatWeOfferBackground from "../Assets/home_client/2.webp";
import icon1 from "../Assets/home_client/new-icons/1.svg";
import icon2 from "../Assets/home_client/new-icons/2.svg";
import icon3 from "../Assets/home_client/new-icons/3.svg";
import icon4 from "../Assets/home_client/new-icons/4.svg";
import icon5 from "../Assets/home_client/new-icons/5.svg";
import icon6 from "../Assets/home_client/new-icons/6.svg";
import ai_robo from "../Assets/home_client/new-icons/ai_robo.webp";
import tech_back from "../Assets/home_client/new-icons/tech_back.webp";
import bg from "../Assets/home_client/new-icons/BG.webp";

import seamlessIcon1 from "../Assets/home_client/new-icons/seamless/1.svg";
import seamlessIcon2 from "../Assets/home_client/new-icons/seamless/2.svg";
import seamlessIcon3 from "../Assets/home_client/new-icons/seamless/3.svg";
import seamlessIcon4 from "../Assets/home_client/new-icons/seamless/4.svg";
import animationIcon from "../Assets/home_client/new-icons/animation.svg";

import seamlessImage from "../Assets/home_client/new-icons/seamless1Dummy.png";
import seamlessImage2 from "../Assets/home_client/new-icons/seamless/seamless2Dummy.png";
import seamlessImage3 from "../Assets/home_client/new-icons/seamless/seamless3Dummy.png";
import seamlessImage4 from "../Assets/home_client/new-icons/seamless/seamless4Dummy.png";
import bannerVideo from "../Assets/home_client/new-icons/bannerVideo.mp4";

export const clientPageData = {
  banner: {
    line1: "Connect with Best developers for your projects",
    line2:
      "Access top-tier developers globally, customized to fit your unique project needs. Build, scale, and succeed with Zeero’s trusted talent pool.",
    buttonText: "Get Started",
    buttonUrl: "https://your-button-url.com",
    videoUrl: bannerVideo,
    // videoUrl: "your-video-url.mp",
  },

  whatZeeroOffersData: {
    title: "What Zeero Offers",
    backgroundImageUrl: whatWeOfferBackground, // Replace with actual background image URL
    offers: [
      {
        icon: icon1,
        title: "Access to Top Talent",
        desc: "Access top-notch software developers on-demand for your projects. Our developers undergo extensive tests, project submissions, and interviews, ensuring their expertise in the industry. ",
      },
      {
        icon: icon2,
        title: "Flexibility and Scalability",
        desc: "Whether you prefer short-term contracts, long-term partnerships, remote or onsite work arrangements, Zeero provides customizable solutions for maximum flexibility and scalability. ",
      },
      {
        icon: icon3,
        title: "Cost-Effective Solutions",
        desc: "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth. ",
      },
      {
        icon: icon4,
        title: "Strategic Partnership",
        desc: "We go beyond simply connecting you with talent. We're your strategic partner, supporting you as a solution to the challenges you face while resource planning.",
      },
      {
        icon: icon5,
        title: "Diverse and Inclusive Teams",
        desc: "Build stronger, more inclusive teams with Zeero's global talent pool, accessing a wide range of perspectives and experiences to promote creativity, innovation, and collaboration.",
      },
      {
        icon: icon6,
        title: "Project Management & 24X7 Support",
        desc: "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth. ",
      },
    ],
  },

  zeeroAIPlatformData: {
    backgroundImageUrl: ai_robo, // Replace with actual background image URL
    line1: "Discover the Power of Zeero’s AI Platform",
    line2:
      "Experience Zeero’s versatile AI-powered hiring tool for swiftly landing top-tier & rigorously vetted engineers.",
    buttonText: "Hire Top Talent",
  },
  topTechnologiesData: {
    backgroundImageUrl: tech_back, // Replace with your background image URL
    title: "Zeero leads you to the top technologies",
    techFields: [
      {
        name: "Python",
        gradient: `linear-gradient(
    45deg, 
    #17B3A9, 
    #0945DF
);`,
      },
      {
        name: "JavaScript",
        gradient: `linear-gradient(
          45deg, 
          #D2428F, 
          #B842C5
      );`,
      },
      {
        name: "Node JS",
        gradient: `linear-gradient(
          45deg, 
          #8374D9, 
          #C55273
      );`,
      },
      {
        name: "Angular JS",
        gradient: `linear-gradient(
          45deg, 
          #6BCFFA, 
          #4473F6
      );`,
      },
      {
        name: "React JS",
        gradient: `linear-gradient(
          45deg, 
          #17B3A9, 
          #0945DF
      );`,
      },
      {
        name: "SQL",
        gradient: `linear-gradient(
          45deg, 
          #D2428F, 
          #B842C5
      );`,
      },
      {
        name: "CSS",
        gradient: `linear-gradient(
          45deg, 
          #8374D9, 
          #C55273
      );`,
      },
      {
        name: "Bitbucket",
        gradient: `linear-gradient(
          45deg, 
          #6BCFFA, 
          #4473F6
      );`,
      },
    ],
  },
  testimonialsSliderData: {
    backgroundImageUrl: bg, // Replace with actual background image URL
    title: "Don’t just take our word for it",
    testimonials: [
      {
        title: "Exceptionally Streamlined",
        desc: "Zeero's platform streamlined our hiring process for backend and full-stack engineers. Their thorough vetting gave us confidence in the quality candidates. For finding developers across various tech stacks.",
        authorName: "Priyanka Sharma",
        authorTitle: "Founder of Impargoes",
        authorImage: "https://randomuser.me/api/portraits/women/1.jpg", // Replace with actual image URL
      },
      {
        title: "Highly Recommended",
        desc: "Zeero made hiring full-stack developers easy for us. We found top-tier talent quickly, and their support team was always available to help. The flexibility to hire for project-based needs was a huge plus.",
        authorName: "Peter Walls",
        authorTitle: "Founder of Admiral Digitals",
        authorImage: "https://randomuser.me/api/portraits/men/1.jpg",
      },
      {
        title: "Great Experience",
        desc: "Our company needed full-stack developers for a new project, and Zeero came through for us. We found developers working in our timezone, making collaboration easy even on a remote basis.",
        authorTitle: "Chairman of Journyfronte",
        authorName: "Koeenaye Wang",
        authorImage: "https://randomuser.me/api/portraits/women/2.jpg",
      },
    ],
  },
  seamlessPartnershipData: {
    part1Title: "Experience Effortless Collaboration with Zeero",

    part2Items: [
      {
        image: seamlessIcon1, // Dummy image URL
        title: "Top Talent",
        desc: "We connect you with skilled developers who match your project needs.",
      },
      {
        image: seamlessIcon2, // Dummy image URL
        title: "Tailored Teams",
        desc: "Build a team specifically suited to your  project’s requirements.",
      },
      {
        image: seamlessIcon3, // Dummy image URL
        title: "Flexible Solutions",
        desc: "Find the right developers for your preferred work  environment, whether on-site, remote, or hybrid.",
      },
      {
        image: seamlessIcon4, // Dummy image URL
        title: "Timely Delivery",
        desc: "Ensure your project stays on track with  developers ready to start when you are.",
      },
    ],

    part3Images: [
      {
        image: seamlessImage, // Dummy image URL
        title: "Backend Developer",
      },
      {
        image: seamlessImage2, // Dummy image URL
        title: "Full Stack Developer",
      },
      {
        image: seamlessImage3, // Dummy image URL
        title: "Data <br /> Scientists",
      },
      {
        image: seamlessImage4, // Dummy image URL
        title: "Frontend Developer",
      },
    ],

    part4Title: "Ready to build your ideal team?",
    part4ButtonText: "Hire Now",
  },
  hireDeveloperData: {
    title: "How to hire a developer?",
    description: "Get started in just 5 steps...",
    animationIcon: animationIcon,
    steps: [
      {
        number: 1,
        title: "Define Your Needs",
        description:
          "Tell us about your project's goals, timeline, and skill requirements. Our Client Success Manager will work with you to ensure a clear understanding.",
      },
      {
        number: 2,
        title: "Review Candidates",
        description:
          "We'll present you with a list of pre-vetted engineers with the skills and experience needed for your project.",
      },
      {
        number: 3,
        title: "Conduct Interviews",
        description:
          "Meet with the top candidates and assess their technical and cultural fit for your project.",
      },
      {
        number: 4,
        title: "Make Your Choice",
        description:
          "Select the right developer for your needs and get ready to start working together.",
      },
      {
        number: 5,
        title: "Begin the Project",
        description:
          "Once the contract is in place, your developer will start working on your project.",
      },
    ],
  },
};

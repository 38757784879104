import { useNavigate } from "react-router-dom";
import "./BackToHome.jsx";
import blurLeftArrow from "../../Assets/SVG/blueLeftArrow.svg";
import "./BackToHome.scss";

const buttonTempDetails = {
  placeholder: "Back to Home",
  url: "/assessment/home",
  hardReload: true,
  icon: blurLeftArrow,
};
export const BackToHome = ({ buttonItem = {} }) => {
  const { placeholder, url, hardReload, icon } = {
    ...buttonTempDetails,
    ...buttonItem,
  };
  const navigate = useNavigate();

  const handleClick = () => {
    if (hardReload) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <div className="back-to-home" onClick={() => handleClick()}>
      <div>
        <img src={icon} alt="" />
      </div>{" "}
      <div>{placeholder}</div>
    </div>
  );
};

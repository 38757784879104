import contactImage from "../Assets/contact_client/contact_client.webp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
export const contactDataClient = {
  breadCrumbs: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Contact",
      link: "/contact/client",
    },
  ],
  leftSide: {
    title:
      "Submit the details & our team will connect with you within 48 hours.",
    desc: "Client’s Details",
    desc2: "Candidate Requirement",
  },
  rightSide: {
    image: contactImage,
    address: [
      {
        Image: MailOutlineIcon,
        title: "info@zeero.us",
      },
      {
        Image: LocationOnOutlinedIcon,
        title: "Zeero, United States",
        desc: "#101 Hudson Street, Jersey City, NJ-07302",
      },
      {
        Image: LocationOnOutlinedIcon,
        title: "Zeero, India",
        desc: "Bengaluru, Karnataka, India",
      },
    ],
  },
};

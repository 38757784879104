import React from "react";

import location from "../../../Assets/SVG/location.svg";
import experience from "../../../Assets/SVG/experience.svg";
import domain from "../../../Assets/SVG/domain.svg";
import "./CandidateProfileCard.scss";
import { useNavigate } from "react-router-dom";

const CandidateProfileCard = ({ background }) => {
  const navigate = useNavigate();
  const handleCardClick = (email) => {
    const data={email:email}
    navigate("/candidate-info", { state: data });
  };
  return (
    <div
      className="profile-card"
      style={{ background }}
      onClick={()=>{
        handleCardClick("pivipo.usomif@gotgel.org")
      }}
    >
      <div className="card-header">
        <div className="availability">
          <span>Availability: </span>
          <span>August 3, 2024</span>
        </div>
        <div className="job-type">
          <span>Preferred Job Type: </span>
          <span>{" Full time"}</span>
        </div>
      </div>
      <div className="card-body">
        <img
          src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          alt="candidate-image"
        ></img>
        <div className="candidate-info">
          <div className="role">Backend Developer</div>
          <div className="name">Archie Rix</div>
          <div className="location">
            <img src={location} alt="location"></img>
            <span>Austin, USA</span>
          </div>
          <div className="experience">
            <img src={experience} alt="experience"></img>
            <span>5 years</span>
          </div>
          <div className="domain">
            <img src={domain} alt="domain"></img>
            <span>Information services & technologies</span>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <span>Angular JS</span>
        <span>Python</span>
        <span>Node.js</span>
        <span>Selenium</span>
        <span>+3</span>
      </div>
    </div>
  );
};

export default CandidateProfileCard;

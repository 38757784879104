import React, { useState } from "react";
import { CandidateHeader } from "../../Components/CandidateHeader/CandidateHeader";
import "./IssueTracker.scss";
import { useTickets } from "../../hooks/useTickets";
import { IssueList } from "./IssueList";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";

export const IssueTracker = () => {
  const [isActive, setIsActive] = useState(true);
  const { issues, viewLoader } = useTickets(isActive);

  const handleIssueButton = (isActive) => {
    setIsActive(isActive);
  }

  return (
    <>
      {viewLoader && <GlobalLoader></GlobalLoader>}
      <CandidateHeader />
      <div className="issue-tracker">
        <div className="title">Issue Tracker</div>
        <div className="issue-tracker__tabs">
          <button className={`${isActive ? "active" : ""}`} onClick={() => handleIssueButton(true)}>Active Issues</button>
          <button className={`${isActive ? "" : "active"}`} onClick={() => handleIssueButton(false)}>Resolved Issues</button>
        </div>
        <div className="issue-list-container">
          {Object.keys(issues).map(issue => <IssueList key={issue} issues={issues} date={issue}></IssueList>)}
        </div>
      </div>
    </>
  );
};
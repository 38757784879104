import axios from "axios";

// const MAX_RETRIES = 5; // Maximum number of retries
// const RETRY_DELAY = 3000; // Delay between retries in milliseconds (3 seconds in this example)

// async function callApiWithRetry(url, options, retries = 0) {
//     try {
//         const response = await fetch(url, options);
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return await response.json();
//     } catch (error) {
//         if (retries < MAX_RETRIES) {
//             console.log(`Error: ${error.message}. Retrying in ${RETRY_DELAY / 1000} seconds...`);
//             await new Promise(resolve => setTimeout(resolve, RETRY_DELAY)); // Delay before retrying
//             return callApiWithRetry(url, options, retries + 1); // Retry the API call
//         } else {
//             throw new Error(`Maximum retry limit (${MAX_RETRIES}) reached.`);
//         }
//     }
// }

export const postTicket = async (data) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        const res = await axios
            .post("/api/faqs/ticket", data, config)
            .then((response) => response?.data)
        return res;

    } catch (err) {
        console.error(err);
        return null;
    }
};

export const fetchTickets = async (data) => {
    try {
        // const apiUrl = '/api/faqs/list/tickets';
        // const apiOptions = {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         // Add any other headers needed
        //     },
        // };

        // return callApiWithRetry(apiUrl, apiOptions)
        //     .then(data => console.log('API response:', data))
        //     .catch(error => console.error('API call failed:', error));
        const res = await axios
            .post("/api/faqs/list/tickets", data)
            .then((response) => response?.data)
        return res;
    } catch (err) {
        console.error(err);
        return { error: "Server Issue" }
    }
}

export const fetchConversations = async (data) => {
    try {
        const res = await axios
            .post("/api/faqs/list/conversations", data)
            .then((response) => response?.data)
        return res;

    } catch (err) {
        console.error(err);
        return { error: "Server Issue" }
    }
}
import contactImage from "../Assets/contact/contact_us_illust.webp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
export const contactData = {
  breadCrumbs: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Contact",
      link: "/contact/all",
    },
  ],
  leftSide: {
    title: "Lets Get in Touch!",
    desc: "Have a question or need assistance? Reach out to us  via email, phone or contact us from below. We’re eager to assist you.",
  },
  rightSide: {
    image: contactImage,
    address: [
      {
        Image: MailOutlineIcon,
        title: "info@zeero.us",
      },
      {
        Image: LocationOnOutlinedIcon,
        title: "Zeero, United States",
        desc: "#101 Hudson Street, Jersey City, NJ-07302",
      },
      {
        Image: LocationOnOutlinedIcon,
        title: "Zeero, India",
        desc: "Bengaluru, Karnataka, India",
      },
    ],
  },
};

import { useState } from "react";
import { faqMessage } from "../mockData/faq";

export const useAttachments = (setShowNotificationDialog, setNotificationDialogData) => {
  const [attachments, setAttachments] = useState([]);
  const imageFormat = ['jpg','png','jpeg']

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const currentAttachments = [...attachments];
    const fileNames = currentAttachments.map((file) => file.name);

    if (currentAttachments.length + files.length > 5) {
      setShowNotificationDialog(true);
      setNotificationDialogData({ ...faqMessage?.ticket?.limitIssue });
      return;
    }

    for (const file of files) {
      const fileType = file?.name?.split(".").pop();

      if (!imageFormat.includes(fileType)) {
        setShowNotificationDialog(true);
        setNotificationDialogData({ ...faqMessage?.ticket?.formatIssue });
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setShowNotificationDialog(true);
        setNotificationDialogData({ ...faqMessage?.ticket?.sizeIssue });
        return;
      }

      if (fileNames.includes(file.name)) {
        return;
      }
    }

    setAttachments([...currentAttachments, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  return {
    attachments,
    handleFileChange,
    removeAttachment,
  };
};

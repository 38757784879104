import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sliderStyles.scss"; // Import the global slider styles
import styles from "./TestimonialsSlider.module.scss"; // Scoped styles

const TestimonialCard = ({ testimonial, animate }) => {
  return (
    <Box className={`${styles.card} ${animate ? styles.animateIn : ""}`}>
      <Typography variant="h6" className={styles.quoteText}>
        “
      </Typography>
      <Typography variant="h6" className={styles.cardTitle}>
        {testimonial?.title}
      </Typography>
      <Typography variant="body1" className={styles.cardDesc}>
        {testimonial?.desc}
      </Typography>
      <Typography
        variant="h6"
        className={styles.quoteText}
        style={{ textAlign: "right" }}
      >
        ”
      </Typography>

      <Box className={styles.authorSection}>
        <img
          src={testimonial?.authorImage}
          alt={testimonial?.authorName}
          className={styles.authorImage}
        />
        <Box>
          <Typography variant="h6" className={styles.authorName}>
            {testimonial?.authorName}
          </Typography>
          <Typography variant="body2" className={styles.authorTitle}>
            {testimonial?.authorTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TestimonialsSlider = ({ data }) => {
  const [componentInView, setComponentInView] = useState(false); // Track if the entire component has been in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once when component comes into view
    threshold: 0.5, // Component comes into view when 50% of it is visible
  });

  // Once the component is in view, set the animation trigger
  if (inView && !componentInView) {
    setComponentInView(true);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      className={styles.container}
      sx={{ backgroundImage: `url(${data?.backgroundImageUrl || ""})` }}
      ref={ref}
    >
      <Typography variant="h4" className={styles.title}>
        {data?.title || "Don’t just take our word for it"}
      </Typography>

      <Slider {...settings} className={styles.slider}>
        {data?.testimonials?.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            testimonial={testimonial}
            animate={componentInView}
          />
        ))}
      </Slider>
    </Box>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { formatDate } from "../../utils/dateUtils";

export const IssueItem = ({ issue, date }) => {
  const navigate = useNavigate();

  return (
    <div className="issue-item" onClick={() => navigate(`/issue-conversation/${issue.ticketId}`, { state: { issue, dateWithTime: formatDate(issue.creationDate, true),dateWithoutTime: formatDate(issue.creationDate, false) } })}>
      <div className="issue-item__content">
        <div>{issue.subject}</div>
        <p>{issue.description}</p>
      </div>
      <div className="issue-item__action">
        <ArrowForwardIosIcon style={{ color: '#A3A3A3', height: '24px', width: '24px' }} />
      </div>
    </div>
  );
};

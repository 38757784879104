import { Layout } from "../../Components/Common/Layout/Layout";
import { BackButton } from "../../Components/Common/BackButton/BackButton";
import "./CandidateInfo.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { useNavigate ,useLocation} from "react-router-dom";
import { Accordion } from "../../Components/Common/Accordion/Accordion";
import CircleIcon from "@mui/icons-material/Circle";
import Rating from "@mui/material/Rating";
import bulb from "../../Assets/SVG/bulb.svg";
import { ProgressBar } from "../../Components/Common/ProgressBar/ProgressBar";
import circleWithGreenDot from "../../Assets/SVG/circle-with-green-dot.svg";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useState } from "react";
import { getCandidateCardInfo ,getCandidateAllInfo} from "../../Common/candidate";
import { formatDateWithYearAndMonth,calculateTotalExperience } from "../../utils/dateUtils";

const CandidateInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackButton = () => {
    navigate("/client-new");
  };
  const [candidateEmail, setCandidateEmail] = useState();
  const [candidateCardInfo, setCandidateCardIndo] = useState({});
  const [candidateInformation, setCandidateInformation] = useState({});

  // /candidate/detailInfo/profile/get/save
  const fetchCandidateCardInfo = async () => {
    const data = {
      name: location.state.email
    };
    const res= await getCandidateCardInfo(data);
    const response=await getCandidateAllInfo(data)
    if (res) {
      setCandidateCardIndo(res);
    }
    if(response){
      setCandidateInformation(response)
    }
  };
  useEffect(() => {
    fetchCandidateCardInfo();
  }, []);
  return (
    <Layout>
      <div className="candidate-info-main">
        <BackButton
          handleBackButton={handleBackButton}
          name={"Back to Developers"}
        />
        <div className="candidate-info-body">
          <CandidateInfoVerticalCard candidateCardInfo={candidateCardInfo} />
          <CandidateDetailInfo  candidateInformation={candidateInformation}/>
        </div>
      </div>
    </Layout>
  );
};

const CandidateInfoVerticalCard = ({ candidateCardInfo }) => {
  const {
    name,
    skill,
    state,
    country,
    experienceInYears,
    industryDomain,
    availability,
    preferredJobType,
    allSkills,
  } = candidateCardInfo;
  return (
    <div className="candidate-info-left">
      <img
        className="candidate-image"
        src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        alt="candidate-image"
      ></img>
      <div className="name">{name}</div>
      <div className="role">{skill}</div>
      <div className="location">
        <LocationOnOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>
          {state}, {country}
        </span>
      </div>
      <div className="experience">
        <WorkOutlineOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{experienceInYears}</span>
      </div>
      <div className="domain">
        <BusinessOutlinedIcon style={{ color: "#0B52D4" }} />
        <span>{industryDomain}</span>
      </div>
      <div className="availability">
        <span>Availability: </span>
        <span>{availability}</span>
      </div>
      <div className="job-type">
        <span>Preferred Job Type: </span>
        <span>{preferredJobType}</span>
      </div>
      <div className="technologies">
        {allSkills
          ?.map((skill) => skill.skillName)
          ?.map((skill) => (
            <span>{skill}</span>
          ))}
      </div>
      <button className="hire-button">Hire Archie</button>
    </div>
  );
};

const CandidateDetailInfo = ({candidateInformation}) => {
  const accordionItems = [
    {
      header: "Vetted Technical Skills",
      Body: () => <VettedTechnicalSkills />,
    },
    {
      header: "Work Experience & Project Highlights",
      Body: () => <WorkExperience  workData={candidateInformation?.workExperience || []}/>,
    },
    {
      header: "Skills & Technologies",
      Body: () => <Skills skills={candidateInformation?.skillsAndTechnologies?.skills || []} />,
    },
    {
      header: "Education",
      Body: () => <Education  education={candidateInformation?.education || []}/>,
    },
    {
      header: "Certification & Achievements",
      Body: () => <Certifications />,
    },
    {
      header: "Recommendations/Testimonials",
      Body: () => <Recommendations />,
    },
    {
      header: "Soft Skills",
      Body: () => <SoftSkills />,
    },
    {
      header: "Languages",
      Body: () => <Languages />,
    },
    {
      header: "Interests",
      Body: () => <Interests data={candidateInformation?.interests?.interests || []} />,
    },
  ];

  return (
    <div className="candidate-info-right">
      <Summary />
      <Accordion
        accordionItems={accordionItems}
        classes={{ main: "accordion-main", header: "header", body: "body" }}
      />
      <div className="share-main">
        <ShareOutlinedIcon className="share" />
        <span className="share-title"> Share with your network</span>
        <div className="social-media">
          <LinkedInIcon className="linkedin" />
          <InstagramIcon className="instagram" />
        </div>
      </div>
    </div>
  );
};

const Summary = () => {
  return (
    <div className="accordion-main">
      <div className="header">
        <span>Summary</span>
      </div>
      <div className="body">
        {" "}
        <div>
          Since 2018, Archie has been working professionally in the fields he
          loves, software and data—culminating in him co-founding the Rubota
          corporation in 2017. Before that, he spent the past decade at Cornell
          University conducting scientific research specifically in statistical
          and biological physics. All in all, Matthew is an engaging, intense
          communicator with a passion for knowledge and understanding.{" "}
        </div>
      </div>
    </div>
  );
};

const VettedTechnicalSkills = () => {
  return (
    <div className="vetted-technical-skills-main">
      <div className="vetted-technical-skills-items">
        <div className="skill-header">
          <div>Skills</div>
          <div>Vetting Result</div>
          <div>Experience</div>
        </div>
        <VettedTechnicalSkillItem progress={60} />
        <VettedTechnicalSkillItem progress={80} />
      </div>
      <div className="skill-description-main">
        <div className="skill-title">Technical Interview note:</div>
        <div className="skill-description">
          Archie demonstrated a strong understanding of HTML fundamentals during
          the interview. He showcased his ability to manipulate arrays
          effectively, highlighting the proficiency in working with data
          structures & algorithm in Javascript.
        </div>
      </div>
    </div>
  );
};

const VettedTechnicalSkillItem = ({ progress }) => {
  return (
    <div className="vetted-skills-item">
      <div className="skill">Angular JS</div>
      <ProgressBar progress={progress} />
      <div className="experience">3 Years</div>
    </div>
  );
};

const WorkExperience = ({workData}) => {
  return (
    <div className="work-experience-items">
      {
        workData.map((work)=>(
          <WorkExperienceItem  work={work}/>
        ))
      }
    </div>
  );
};

const WorkExperienceItem = ({work}) => {
  return (
    <div className="work-experience-item">
      <div className="item-container">
        <div className="designation-main">
          <div className="designation">
            <div className="circle-with-green-dot">
              <img src={circleWithGreenDot} alt="circle-with-green-dot"></img>
            </div>
            <span>{work?.position || ""}</span>
            <div className="dot">
              <CircleIcon style={{ color: "green", fontSize: "10px" }} />
            </div>
            <span> {formatDateWithYearAndMonth(work?.startDate || "")} - {work?.endDate==="present"?"Present":formatDateWithYearAndMonth(work?.endDate || "")} ({calculateTotalExperience(work?.startDate,work?.endDate)})</span>
          </div>
          <div className="company">{work?.company}</div>
          <div className="location">
            <span>Location: </span>
            <span>{work?.location}</span>
          </div>
        </div>
        <div className="project-main">
          <div className="project-header">Project:</div>
          <div className="project-title">Booking Application for travelers</div>
          <div className="project-description">
            An online booking system for travelers, offering access to 200,000
            tours in over 2,000 destinations worldwide. It simplifies travel
            planning by providing recommendations on top attractions and
            allowing easy booking of activities. Optimized for mobile, it
            enables users to plan ahead or book on-the-go, avoiding long lines
            and sold-out signs.
          </div>
        </div>
        <div className="responsibilities-main">
          <div className="responsibilities-header">
            Responsibilities & Achievements:
          </div>
          <ul className="responsibilities-list">
            { work?.highlights.map((highligh)=>(
               <li>{highligh}</li>
            ))
             
            }
          </ul>
        </div>
        <div className="tech-stacks">
          <div className="tech-stack-header">Technologies stack:</div>
          <div className="tech-stack-list">
           {work?.techStack.map((tech)=>(
            <span>{tech}</span>
           ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Skills = ({skills}) => {
  return (
    <div className="skills">
      {
        skills?.map((skill)=>(
          <SkillItem skill={skill} />
        ))
      }
      
    </div>
  );
};

const SkillItem = ({skill}) => {
  return (
    <span className="skill-item">
      <span className="skill">{skill?.name}</span>
      
    </span>
  );
};

const Education = ({education}) => {
  return (
    <div className="education-certifications">
      {
        education.map((item)=>(
          <EducationCertificationItem  education={item}/>
        ))
      }
     
    </div>
  );
};

const Certifications = ({}) => {
  return (
    <div className="education-certifications">
      {/* <EducationCertificationItem education={certifications}/>
      <EducationCertificationItem /> */}
    </div>
  );
};

const EducationCertificationItem = ({education}) => {
  return (
    <div className="education-certification-item">
      <div className="name">
        <span className="title">
          {education?.degree}
        </span>
        <div className="dot">
          <CircleIcon style={{ color: "green", fontSize: "10px" }} />
        </div>
        <span>{formatDateWithYearAndMonth(education?.startDate)} - {formatDateWithYearAndMonth(education?.endDate)}</span>
      </div>
      <div className="place">{education?.institution}</div>
      <div className="location">
        <span>Location: </span>
        <span>{education?.location}</span>
      </div>
    </div>
  );
};

const Recommendations = () => {
  return (
    <div className="recommendations">
      <RecommendationItem />
      <RecommendationItem />
    </div>
  );
};

const RecommendationItem = () => {
  return (
    <div className="recommendation-item">
      <div className="header">Dean Academics, BVM</div>
      <div className="name">Dr. V.J. Patel</div>
      <div className="description">
        “Archie has demonstrated exceptional skill and dedication as a backend
        engineer. His ability to troubleshoot and optimize our server
        infrastructure has significantly improved our application's performance.
        He is not only technically proficient but also excellent at
        communicating complex ideas in a way that everyone can understand. I
        highly recommend him for any backend development needs."
      </div>
    </div>
  );
};

const SoftSkills = () => {
  return (
    <div className="soft-skills-main">
      <div className="soft-skills-items">
        <SoftSkillItem progress={80} />
        <SoftSkillItem progress={60} />
      </div>
      <div className="description">
        Archie excels in problem-solving, quickly identifying issues and
        developing effective solutions. His strong communication skills ensure
        clear and concise information exchange. He efficiently manages time,
        prioritizing tasks to meet deadlines. As a leader, he inspires and
        motivate his team, fostering a collaborative environment.
      </div>
    </div>
  );
};

const SoftSkillItem = ({ progress }) => {
  return (
    <div className="soft-skill-item">
      <div className="skill">
        <img src={bulb} alt="problem-solving"></img>
        <span>Problem Solving</span>
      </div>
      <ProgressBar progress={progress} />
      <div className="remarks">Excellent</div>
    </div>
  );
};

const Languages = () => {
  return (
    <div className="languages">
      <LanguageItem />
      <LanguageItem />
      <LanguageItem />
      <LanguageItem />
      <LanguageItem />
    </div>
  );
};

const LanguageItem = () => {
  return (
    <div className="language-item">
      <span>English</span>
      <Rating
        name="language-rating"
        readOnly="true"
        defaultValue={2}
        icon={<CircleIcon style={{ color: "#062B6E" }} />}
        emptyIcon={<CircleIcon style={{ color: "#A5B3CB" }} />}
        IconContainerComponent={IconContainer}
      />
      <span>Fluent</span>
    </div>
  );
};

const IconContainer = ({ children }) => {
  return <span style={{ margin: "0 4px", display: "flex" }}>{children}</span>;
};

const Interests = ({ data }) => {
  return (
    <div className="interests">
      {data.map((item) => (
        <span>{item}</span>
      ))}
    </div>
  );
};

export default CandidateInfo;

import React from "react";
import { useInView } from "react-intersection-observer";
import styles from "./SeamlessPartnership.module.scss"; // Import styles
import { Button } from "@mui/material";

export const SeamlessPartnership = ({ data }) => {
  // Animation hooks for all parts
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {/* Part 1: Title */}
        <div
          ref={ref1}
          className={`${styles.part1Title} ${inView1 ? styles.animateIn : ""}`}
        >
          <div dangerouslySetInnerHTML={{ __html: data?.part1Title }} />
        </div>

        {/* Part 2: Four Items Grid - Row-wise Layout */}
        <div className={styles.gridContainer}>
          {data?.part2Items?.map((item, index) => (
            <div
              key={index}
              ref={ref2}
              className={`${styles.part2Item} ${
                inView2 ? styles.animateIn : ""
              }`}
            >
              <div className={styles.imageWrapper}>
                <img
                  src={item.image}
                  alt={item.title}
                  className={styles.part2Image}
                />
              </div>
              <div className={styles.textWrapper}>
                <h6 className={styles.part2Title}>{item.title}</h6>
                <p className={styles.part2Desc}>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Part 3: Four Images in a Single Row */}
        <div
          ref={ref3}
          className={inView3 ? styles.animateIn : styles.part3Grid}
        >
          <div className={styles.part3Row}>
            {data?.part3Images?.map((imgItem, index) => (
              <div key={index} className={styles.part3Item}>
                <div className={styles.part3ImageContainer}>
                  <img
                    src={imgItem.image}
                    alt={imgItem.title}
                    className={styles.part3Image}
                  />
                  <h6
                    className={styles.part3ImageTitle}
                    dangerouslySetInnerHTML={{ __html: imgItem.title }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Part 4: CTA */}
        <div
          ref={ref4}
          className={inView4 ? styles.animateIn : styles.part4Container}
        >
          <div className={styles.part4Title}>{data?.part4Title}</div>
          <div className={styles.buttonContainer}>
            <Button className={styles.part4Button}>
              {data?.part4ButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

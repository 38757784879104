export const extractConversation = (input) => {
    const messageRegex = /^(.*?) ----/;
    const dateRegex = /on \w{3}, (\d{2} \w{3} \d{4} \d{2}:\d{2}:\d{2}) \+\d{4}/;
  
    const messageMatch = input.match(messageRegex);
    const dateMatch = input.match(dateRegex);
  
    const message = messageMatch ? messageMatch[1].trim() : null;
    let date = dateMatch ? dateMatch[1].trim() : null;
  
    if (date) {
      const [day, month, year, time] = date.split(" ");
      const monthMap = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
      const formattedDateStr = `${year}-${monthMap[month]}-${day}T${time}`;
      const dateObj = new Date(formattedDateStr);
  
      const today = new Date();
      const isToday = dateObj.toDateString() === today.toDateString();
  
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const timeFormatted = dateObj.toLocaleTimeString('en-US', options);
  
      if (isToday) {
        date = `Today, ${timeFormatted}`;
      } else {
        const datePart = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
        date = `${datePart}, ${timeFormatted}`;
      }
    }
  
    return { message, date };
  };
  
import React from "react";
import { Button, Typography } from "@mui/material";
import styles from "./Banner.module.scss";

export const TalentBanner = ({ data }) => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.textContent}>
        <Typography variant="h4" component="h1" className={styles.text1}>
          {data?.line1}
        </Typography>
        <Typography variant="body1" component="p" className={styles.text2}>
          {data?.line2}
        </Typography>
        <Button
          variant="contained"
          className={styles.getStartedButton}
          href={data.buttonUrl}
        >
          {data?.buttonText}
        </Button>
      </div>
      <div className={styles.videoContent}>
        <video
          src={data?.videoUrl}
          autoPlay
          muted
          // loop
          playsInline
          className={styles.bannerVideo}
        />
      </div>
    </div>
  );
};

import { postTicket, fetchTickets } from "../Common/faq";
import { faqMessage } from "../mockData/faq";

export const createTicket = async (data, setViewRaiseTicket, setShowNotificationDialog, setNotificationDialogData) => {
    try {
        const response = await postTicket(data);
        if (response) {
            setViewRaiseTicket(false);
            setShowNotificationDialog(true);
            setNotificationDialogData({ ...faqMessage?.ticket?.success });
        } else {
            setShowNotificationDialog(true);
            setNotificationDialogData({ ...faqMessage?.ticket?.serverIssue });
        }
    } catch (error) {
        console.error("Error in createTicket:", error);
    }
};

export const getTickets = async (data) => {
    try {
        return await fetchTickets(data);
    } catch (err) {
        console.error("Error in getTicket:", err);
    }
};
import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./HireDeveloper.module.scss";

export const HireDeveloper = ({ hireDeveloperData }) => {
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 1 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 1 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 1 });
  const { ref: ref5, inView: inView5 } = useInView({ threshold: 1 });

  const [currentIndex, setCurrentIndex] = useState(1);
  const [visibleStepIndex, setVisibleStepIndex] = useState(1); // State for visible number on the left side
  const iconRef = useRef(null); // Ref for the icon
  const numberRefs = useRef([]); // Refs for the number elements

  useEffect(() => {
    // Update the currentIndex to the previous number while the next number comes into view
    if (inView5) setCurrentIndex(4); // Icon stays at 4 when 5 is in view
    else if (inView4) setCurrentIndex(3); // Icon stays at 3 when 4 is in view
    else if (inView3) setCurrentIndex(2); // Icon stays at 2 when 3 is in view
    else if (inView2) setCurrentIndex(1); // Icon stays at 1 when 2 is in view
  }, [inView1, inView2, inView3, inView4, inView5]);

  useEffect(() => {
    // Update the visibleStepIndex when a step becomes visible on the right side
    if (inView5) setVisibleStepIndex(5);
    else if (inView4) setVisibleStepIndex(4);
    else if (inView3) setVisibleStepIndex(3);
    else if (inView2) setVisibleStepIndex(2);
    else if (inView1) setVisibleStepIndex(1);
  }, [inView1, inView2, inView3, inView4, inView5]);

  useEffect(() => {
    // Move the icon to the previous number (the number before the current one in view)
    if (numberRefs.current[currentIndex - 1] && iconRef.current) {
      const numberRect =
        numberRefs.current[currentIndex - 1].getBoundingClientRect();
      const numberRowRect =
        numberRefs.current[0].parentNode.getBoundingClientRect();

      // Calculate the distance and move the icon
      const translateX = numberRect.left - numberRowRect.left;
      iconRef.current.style.transform = `translateX(${translateX}px)`;
    }
  }, [currentIndex]);

  return (
    <div className={styles.container}>
      {/* Left side: Title, description, and numbers */}
      <div className={styles.subContainer}>
        <div className={styles.leftSide}>
          <h2>{hireDeveloperData?.title}</h2>
          <p>{hireDeveloperData?.description}</p>

          <div className={styles.numberRow}>
            {[1, 2, 3, 4, 5].map((number, index) => (
              <div
                key={index}
                className={`${styles.numberCircle} ${
                  visibleStepIndex >= number ? styles.animateIn : ""
                }`}
                ref={(el) => (numberRefs.current[index] = el)} // Assign ref to each number
              >
                {number < 10 ? `0${number}` : number}
              </div>
            ))}
          </div>

          {/* Moving Icon */}
          <div className={styles.iconWrapper}>
            <img
              src={hireDeveloperData?.animationIcon}
              alt="Person Icon"
              ref={iconRef}
              className={styles.movingIcon}
            />
          </div>
        </div>

        {/* Right side: Steps */}
        <div className={styles.rightSide}>
          {[ref1, ref2, ref3, ref4, ref5].map((ref, index) => (
            <div
              key={index}
              className={`${styles.step} ${
                [inView1, inView2, inView3, inView4, inView5][index]
                  ? styles.animateIn
                  : ""
              }`}
              ref={ref}
            >
              <div className={styles.stepHeader}>
                <div className={styles.stepNumber}>{index + 1}</div>
                <h4>{hireDeveloperData?.steps[index].title}</h4>
              </div>
              <p className={styles.stepDesc}>
                {hireDeveloperData?.steps[index].description}
              </p>
              {index < hireDeveloperData?.steps.length - 1 && (
                <div
                  className={`${styles.line} ${
                    [inView1, inView2, inView3, inView4, inView5][index + 1]
                      ? styles.visible
                      : ""
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import axios from "axios";

export const getAllCountries = async () => {
  return await axios
    .get("/api/region/countries")
    .then((response) => response?.data);
};

export const getStates = async (data) => {
  const res = await axios
    .post("/api/region/states", data)
    .then((response) => response?.data);
  return res;
};

export const getCities = async (data) => {
  const res = await axios
    .post("/api/region/cities", data)
    .then((response) => response?.data);
  return res;
};

export const getAllTimeZones = async () => {
  return await axios
    .get("/api/region/timeZone")
    .then((response) => response?.data);
};

// export const getStateByZipCode=()=>{

// }

import { useEffect, useState } from "react";
import { getTickets } from "../services/ticketService";

export const useTickets = (isActive) => {
  const [allIssues, setAllIssues] = useState([]);
  const [issues, setIssues] = useState([]);
  const [viewLoader, setViewLoader] = useState(false);

  useEffect(() => {
    const getAllTickets = async () => {
      setViewLoader(true);
      try {
        const email = localStorage.getItem("email");
        const data = { name: email };
        const res = await getTickets(data);
        if (Array.isArray(res)) {
          setAllIssues(res);
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setViewLoader(false);
      }
    };

    getAllTickets();
  }, []);

  useEffect(() => {
    const filterAndGroupTickets = () => {
      const filteredTickets = filterTickets(allIssues, isActive);
      const groupedTickets = groupTicketsByDate(filteredTickets);
      const sortedGroupedTickets = sortTickets(groupedTickets);
      setIssues(sortedGroupedTickets);
    };

    filterAndGroupTickets();
  }, [isActive, allIssues]);

  const filterTickets = (tickets, isActive) => {
    const status = isActive ? 'Open' : 'Closed';
    return tickets.filter(ticket => ticket.status === status);
  };

  const groupTicketsByDate = (tickets) => {
    return tickets.reduce((groups, ticket) => {
      const date = new Date(ticket.creationDate).toISOString().split('T')[0]; // Extract the date part
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(ticket);
      return groups;
    }, {});
  };

  const sortTickets = (groupedTickets) => {
    const sortedDates = Object.keys(groupedTickets).sort((a, b) => new Date(b) - new Date(a));
    return sortedDates.reduce((sortedGroups, date) => {
      sortedGroups[date] = groupedTickets[date].sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
      return sortedGroups;
    }, {});
  };

  return { issues, viewLoader };
};

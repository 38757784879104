import { CandidateHeader } from '../../Components/CandidateHeader/CandidateHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AgentAvator from "../../Assets/SVG/agent-avatar.svg"
import React, { useEffect, useState } from 'react';
import './IssueConversation.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { fetchConversations } from '../../Common/faq';
import { extractConversation } from '../../utils/extractConversation';

export const IssueConversation = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const { issue, dateWithTime, dateWithoutTime } = location.state || {};
  useEffect(() => {
    getConversationList();
  }, [])

  const getConversationList = async () => {
    try {
      const data = {
        ticketId: id
      }
      const res = await fetchConversations(data);
      if (res?.data) {
        const agentConversation = res?.data?.filter(d => d.author.type === "AGENT");
        const newMessages = agentConversation.map(convo => {
          const { id, summary } = convo;
          const { message, date } = extractConversation(summary);
          return {
            id,
            text: message,
            timestamp: date
          };
        });

        setMessages(newMessages);

      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  }

  // const [newMessage, setNewMessage] = useState('');

  // const handleSendMessage = () => {
  //   if (newMessage.trim()) {
  //     const newMessageObj = {
  //       id: messages.length + 1,
  //       text: newMessage,
  //       timestamp: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
  //       type: 'sent',
  //     };
  //     setMessages([...messages, newMessageObj]);
  //     setNewMessage('');
  //   }
  // };

  return (
    <>
      <CandidateHeader />
      <div className="issue-conversation-container">
        <div className='header-main'>
          <Link to={"/issue-tracker"} style={{ textDecoration: "none" }}>
            <div className='header-button'>
              <ArrowBackIosIcon style={{ color: '', height: '24px', width: '24px' }}></ArrowBackIosIcon>
              <div className='header-title'>
                Back to issue Tracker
              </div>
            </div>
          </Link>
          <div className='header-date'><span>Ticket creation Date: </span>{dateWithoutTime}</div>
        </div>
        <div className='ticket-main'>
          <div className='ticket-info'>
            <div className='ticket-subject'>{issue.subject}</div>
            <div className='ticket-description'>{issue.description}</div>
          </div>
          <div className="ticket-timestamp"><span>{dateWithTime}</span></div>
        </div>
        <div className="messages">
          {messages.map((message) => (
            <React.Fragment key={message.id}>
              <div className='message-container'>
                <div key={message.id} className={`message`}>
                  {message.text}
                </div>
                <div className='avator'>
                  <img src={AgentAvator}></img>
                </div>
              </div>
              <div className="timestamp">{message.timestamp}</div>
            </React.Fragment>
          ))}
        </div>
        {/* <div className="inputContainer">
          <AttachmentIcon className="attachmentIcon" />
          <input
            type="text"
            className="input"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a new message..."
          />
          <button className="sendButton" onClick={handleSendMessage}>
            <SendIcon />
          </button>
        </div> */}
      </div>
    </>
  );
};


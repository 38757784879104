import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import styles from "./TopTechnologies.module.scss";

export const TopTechnologies = ({ data }) => {
  return (
    <Box
      className={styles.container}
      sx={{ backgroundImage: `url(${data?.backgroundImageUrl || ""})` }}
    >
      <Typography variant="h4" className={styles.title}>
        {data?.title}
      </Typography>
      <Grid container spacing={3} className={styles.techGrid}>
        {data?.techFields?.map((tech, index) => (
          <TechField tech={tech} key={index} /> // Use TechField for each item
        ))}
      </Grid>
    </Box>
  );
};

const TechField = ({ tech }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        className={`${styles.techField} ${inView ? styles.animateIn : ""}`}
        ref={ref}
        sx={{ background: tech?.gradient }}
      >
        <Typography variant="h6" className={styles.techTitle}>
          {tech?.name || "Technology"}
        </Typography>
      </Box>
    </Grid>
  );
};

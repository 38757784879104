import uploadSuccess from "../Assets/SVG/upload-success.svg";
import uploadFailure from "../Assets/SVG/upload-failure.svg"

export const faqMessage = {
    ticket: {
        success: {
            message1: "",
            message2: "The issue has been raised successfully!",
            icon: uploadSuccess,
            buttonName: "Okay"
        },
        serverIssue: {
            message1: "",
            message2: "There seems to be an issue in raising a ticket.",
            icon: uploadFailure,
            buttonName: "Please try again"
        },
        limitIssue: {
            message1: "",
            message2: "Maximum 5 attachments can be uploaded.",
            icon: uploadFailure,
            buttonName: "Okay"
        },
        sizeIssue: {
            message1: "",
            message2: "Maximum file size is 5 MB.",
            icon: uploadFailure,
            buttonName: "Okay"
        },
        formatIssue: {
            message1: "",
            message2: "Invalid file format. Only Jpeg, jpg & png is allowed.",
            icon: uploadFailure,
            buttonName: "Okay"
        }
    }
}
import React from "react";
import "./CandidateProfiles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CandidateProfileCard from "../CandidateProfileCard/CandidateProfileCard";
import { useInView } from "react-intersection-observer";

const BACKGROUNDS = [
  "linear-gradient(177deg, rgba(145, 199, 141, 0.56) -13.49%, rgba(6, 188, 1, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(154, 169, 219, 0.56) -13.49%, rgba(33, 60, 152, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(156, 44, 243, 0.56) -13.49%, rgba(58, 73, 249, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(207, 188, 139, 0.56) -13.49%, rgba(178, 133, 11, 0.56) 109.75%)",
  "linear-gradient(177deg, rgba(253, 148, 255, 0.56) -13.49%, rgba(157, 3, 160, 0.56) 109.75%)",
];

export const CandidateProfiles = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: "linear",
    arrows: false,
  };

  // Animation for when the component comes into view
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div
      className={`candidate-profiles-main ${inView ? "animate" : ""}`}
      ref={ref}
    >
      <div className="header">
        One global platform of high-performing tech talent
      </div>
      <div
        className={`profile-card-container ${inView ? "animate-slider" : ""}`}
      >
        <Slider {...settings}>
          {[...Array(5).keys()].map((_, idx) => (
            <div key={idx} className="slide">
              <CandidateProfileCard
                background={BACKGROUNDS[idx % BACKGROUNDS.length]}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import styles from "./ZeeroAIPlatform.module.scss";

export const ZeeroAIPlatform = ({ data }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers every time it comes into view
    threshold: 0.1, // Animation starts when 10% of the element is in view
  });

  return (
    <Box
      className={`${styles.container} ${inView ? styles.animateIn : ""}`}
      sx={{ backgroundImage: `url(${data?.backgroundImageUrl || ""})` }}
      ref={ref}
    >
      <Typography variant="h4" className={styles.line1}>
        {data?.line1 || "Discover the Power of Zeero’s AI Platform"}
      </Typography>
      <Typography variant="body1" className={styles.line2}>
        {data?.line2 ||
          "Experience Zeero’s versatile AI-powered hiring tool for swiftly landing top-tier & rigorously vetted engineers."}
      </Typography>
      <Button variant="contained" className={styles.hireButton}>
        {data?.buttonText || "Hire Top Talent"}
      </Button>
    </Box>
  );
};

import React from "react";
import { useInView } from "react-intersection-observer";
import { Box, Typography } from "@mui/material";
import styles from "./WhatZeeroOffers.module.scss";

export const WhatZeeroOffers = ({ data }) => {
  // Ref for row 1 animation
  const { ref: refRow1, inView: inViewRow1 } = useInView({
    triggerOnce: true,
    threshold: 0.3, // Trigger animation when 30% of row 1 is visible
  });

  // Ref for row 2 animation
  const { ref: refRow2, inView: inViewRow2 } = useInView({
    triggerOnce: true,
    threshold: 1, // Trigger animation when 30% of row 2 is visible
  });

  return (
    <Box
      className={styles.container}
      sx={{ backgroundImage: `url(${data?.backgroundImageUrl || ""})` }}
    >
      <Typography variant="h4" className={styles.title}>
        {data?.title}
      </Typography>

      {/* Row 1 */}
      <Box ref={refRow1} className={styles.offersGrid}>
        {data?.offers?.slice(0, 3).map((offer, index) => (
          <Box
            key={index}
            className={`${styles.offerBox} ${
              inViewRow1 ? styles.animateIn : ""
            }`}
          >
            <Box className={styles.icon}>
              <img src={offer?.icon} alt={offer?.title || "Icon"} />
            </Box>
            <Typography variant="h6" className={styles.offerTitle}>
              {offer?.title || "Title"}
            </Typography>
            <Typography variant="body1" className={styles.offerDesc}>
              {offer?.desc || "Description"}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Row 2 */}
      <Box ref={refRow2} className={styles.offersGrid}>
        {data?.offers?.slice(3, 6).map((offer, index) => (
          <Box
            key={index}
            className={`${styles.offerBox} ${
              inViewRow2 ? styles.animateIn : ""
            }`}
          >
            <Box className={styles.icon}>
              <img src={offer?.icon} alt={offer?.title || "Icon"} />
            </Box>
            <Typography variant="h6" className={styles.offerTitle}>
              {offer?.title || "Title"}
            </Typography>
            <Typography variant="body1" className={styles.offerDesc}>
              {offer?.desc || "Description"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

import React from "react";
import { IssueItem } from "./IssueItem";
import { formatDate } from "../../utils/dateUtils";

export const IssueList = ({ issues, date }) => {
    return (
        <div className="issue-list">
            {date && <div className="issue-list__date">{formatDate(date)}</div>}
            {issues[date].map(issue => (
                <React.Fragment key={issue.ticketId}>
                    <IssueItem issue={issue} date={date} />
                </React.Fragment>
            ))}
        </div>
    );
};
